import React from 'react'
import LandingPageProduct from '../../../../templates/landing-page-product'
import '../../../../styles/main.scss';

import content from '../../../../data/pages/lp/product/content.json'

const ProductLP = () => (
  <LandingPageProduct content={content} ctaURL='https://secure.performancegolf.com/orderForms/simple-strike-sequence-sc-32947-outbrain' />
)

export default ProductLP
